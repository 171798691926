@keyframes hide-menu {
    0% {
        max-width: 235px;
        min-width: 235px;
    }

    100% {
        min-width: 0;
        max-width: 0;
        opacity: 0;
    }
}

@keyframes show-menu {
    0% {
        min-width: 0;
        max-width: 0;
        opacity: 0;
    }

    100% {
        max-width: 235px;
        min-width: 235px;
    }
}

@keyframes body-full-width {
    0% {
        margin-left: 235px;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes body-condensed {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 235px;
    }
}

$transition: 20ms ease-in 0s;


@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}


@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



@keyframes flip-horizontal-bottom {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
    }

    100% {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
}

@keyframes spin {

    100% {
        transform: rotate(360deg);
    }
}