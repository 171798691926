.home-page {
    height: 100%;
    padding: 40px;

    .welcome-text {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        h2 {
            font-weight: 500;
            color: $main-text-color;
            font-size: 1.875rem;
        }
    }

    .streak-container {
        @include app_radius;
        background-color: white;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding: 1.25rem 2.5rem;
        max-width: 38rem;
        display: flex;
        align-items: center;

        img {
            width: 20%;
        }

        .streak-text {

            width: 80%;
            padding-left: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: .5rem;


            div {
                font-size: 1.25rem;
                font-weight: 600;
            }

            span {
                font-size: .875rem;
            }
        }
    }

    section {
        .section-header {
            margin-bottom: 1rem;

            h5 {
                font-size: 1rem;
                font-weight: 700;
                color: $main-text-color;
            }
        }

        .section-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            row-gap: 1rem;
            column-gap: 1rem;
            margin-bottom: 2rem;

            .set-card {
                @include app_radius;
                @include border_bottom_purple;
                min-width: 300px;
                padding: 0.75rem 1rem;
                width: 32.5%;
                height: 10rem;
                background-color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-decoration: none;



                .set-card__header {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 0.75rem;

                    .card-info {
                        padding-right: 1rem;

                        .card-info__title {
                            overflow-wrap: break-word;
                            font-size: 1rem;
                            font-weight: 600;
                            color: $main-text-color;
                        }

                        .card-info__meta {
                            padding-top: 0.5rem;
                            color: $minor-text-color;
                            font-weight: 600;
                            font-size: 0.875rem;
                        }
                    }

                    .card-thumbnail {
                        max-width: 5rem;
                    }
                }

                .set-card__footer {
                    display: flex;

                    .footer-user {
                        display: flex;
                        align-items: center;

                        span {
                            padding: 0 0.5rem;
                            color: $main-text-color;
                            font-size: 0.875rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}