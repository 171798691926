// Colors
$dark-gray: rgb(55, 53, 47);
$medium-gray: rgba($dark-gray, 0.4);
$home-gray: #B6B6B6;
$yellow: #ffcd1f;
$yellow-hover: #ffc600;
$orange: #fe984a;
$blue: #3ccfcf;
$blue-hover: #00a8a8;
$light-blue: #59e8b5;
$error-red: #ff7873;

$app-background: #f6f7fb;

$main-purple: #4255ff;
$dark-purple: #423ed8;
$hover-purple: #a8b1ff;
$minor-purple: #658EFF;



// Text colors
$main-text-color: #282e3e;
$minor-text-color: #646f90;
$gray-text-color: #939bb4;

// Shadow

$shadow: 0 0.125rem 0.5rem 0 #30354514;

// Padding

// Font
$normal-font-size: 0.875rem;
$menu-font-weight: 600;
$back-up-fonts: "Helvetica, sans-serif";

//size

$nav-height: 64px;