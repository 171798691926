@mixin notion_input {
    padding: 4px 6px;
    border-radius: 3px;
    color: $dark-gray;
    font-size: $normal-font-size;
    background-color: rgba(242, 241, 238, 0.6);
    border: 1px solid rgba(0, 0, 0, 0);
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;

    &:focus {
        border: 1px solid rgb(46, 170, 220);
        box-shadow: 0px 0px 0px 2.5px rgba(46, 170, 220, 0.4);
    }
}

@mixin app_radius {
    border-radius: 0.25rem;
    box-shadow: $shadow;
}

@mixin purple_btn {
    background-color: $main-purple;
    color: white;
    padding: 0.75rem 1rem;
    font-weight: 600;
    border-radius: .5rem;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
        background-color: $dark-purple;
    }
}

@mixin yellow_btn {
    @include purple_btn;
    background-color: $yellow;

    &:hover {
        background-color: $yellow-hover;
    }
}

@mixin blue_btn {
    @include purple_btn;
    background-color: $blue;

    &:hover {
        background-color: $blue-hover;
    }
}

@mixin border_bottom_purple {
    border-bottom: 0.3125rem solid #fff;

    &:hover {
        border-bottom: 0.3125rem solid $hover-purple;
        transition: all .12s cubic-bezier(.47, 0, .745, .715);
    }
}