.create-deck {
    padding: 0rem 4rem;
    max-width: 81.25rem;
    margin: auto;


    .create-deck__header {
        height: 6.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 1.25rem;
            font-weight: 700;
            color: $main-text-color;
        }


    }



    .create-deck__tab {
        .back-btn {
            font-weight: 600;
            cursor: pointer;

            &:hover {
                span {
                    color: $yellow;
                }
            }

            span {
                color: $blue;
                margin-right: 5px;
                font-size: 24px;
                font-weight: bold;
            }
        }

        .upper-btns {
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: space-between;

            .group-btns {
                display: flex;
                column-gap: 1rem;
            }



            .main-btn {
                @include purple_btn;
                text-decoration: none;
            }

            .save-btn {
                @include purple_btn;
                background-color: $blue;

                &:hover {
                    background-color: $yellow;
                    color: black;
                }
            }

            .disabled.save-btn {
                &:hover {
                    color: white;
                    background-color: $blue;
                }
            }
        }


        &.next {
            animation: slide-in-right 0.4s;

            &[tab='1'] {
                display: none;
            }

            &[start='1'] {
                animation: fade-in 0.4s;
            }
        }

        &.back {
            animation: slide-in-left 0.4s;

            &[tab='0'] {
                display: none;
            }
        }

        .create-deck__info {
            display: flex;

            .info-input-card {
                width: 60%;

                .info-row {
                    padding: 0.25rem 0;

                    .css-1ig4r69-MuiInputBase-root-MuiInput-root::before,
                    .css-17j4800-MuiInputBase-root-MuiInput-root::before {
                        border-bottom-width: 1.5px;
                    }

                    .css-1ig4r69-MuiInputBase-root-MuiInput-root::after,
                    .css-17j4800-MuiInputBase-root-MuiInput-root::after {
                        border-bottom-width: 2px;
                    }
                }
            }

            .info-visible-card {
                display: flex;
                width: 40%;
                padding-left: 100px;
                padding-top: 50px;
                align-items: center;
                justify-content: center;


                .visible-select {
                    padding-right: 2rem;
                }
            }

        }

        .upload-card {
            display: flex;
            flex-direction: column;
            align-items: center;

            .selected-image {
                max-width: 100%;
                max-height: 200px;
                border-radius: 6px;
                border: 4px solid $blue;
                margin-top: 2rem;
            }
        }
    }

    .red-bottom {
        border-bottom: 4px solid $error-red;
    }

    .term-card {
        width: 100%;
        background-color: white;
        border-radius: 0.5rem;
        margin-bottom: 20px;



        .term-toolbar {
            padding: 0.75rem;
            border-bottom: 2px solid $app-background;
            display: flex;
            transition: all .12s ease;

            .index {
                color: $gray-text-color;
                font-size: 1rem;
                font-weight: 700;
                width: 2.5rem;
                padding-left: 0.5rem;

            }

            .error-session {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;

                .error-message {
                    display: flex;
                    align-items: center;
                    // background-color: #ffe0e0;
                    color: $error-red;
                    padding: 10px;
                    border-radius: 5px;
                    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    margin-bottom: 10px;

                    i {
                        font-size: 1.5rem;
                        margin-right: 10px;
                    }

                    span {
                        font-size: 1rem;
                        font-weight: 500;
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        .term-content {
            display: flex;
            height: 120px;

            .term {
                width: 45%;
                padding: 1rem;
            }

            .desc {
                width: 45%;
                padding: 1rem;
            }

            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10%;



                .upload-btn {
                    border: 0.125rem dashed #d9dde8;
                    border-radius: .25rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 4rem;
                    width: 5rem;

                    &:hover {
                        border-color: $yellow;
                        color: $yellow;
                    }

                    div {
                        font-size: 0.7rem;
                        color: $minor-text-color;
                        font-weight: 600;
                    }
                }

                .avatar-image {
                    height: auto;
                    max-width: 80%;
                    max-height: 100%;
                }
            }
        }

        .term-image {
            border-top: 2px solid $app-background;
            padding: 1rem;
            display: flex;


            .image-url {
                width: 70%;
            }

            .google-photos {
                width: 70%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 8rem;

                .loading-image {
                    display: block;
                    margin: auto;
                    width: 12%;
                    animation: spin 2s linear infinite;
                }

                .google-image {
                    box-sizing: content-box;
                    border: 3px solid transparent;
                    width: 18%;
                    height: auto;
                    max-height: 100%;

                    /* Set up the transition effect */
                    transition: all 0.3s ease-in-out;

                    /* Set up the hover effect */
                    &:hover {
                        border-color: $yellow;
                        transform: scale(1.1);
                    }

                    /* Set up the click effect */
                    &:active {
                        transform: scale(0.9);
                    }
                }

            }

            .local-upload {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .add-terms-tab {
        .add-more-container {
            padding: 2rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8rem;
            background-color: white;
            border-radius: 0.5rem;
            margin-bottom: 20px;
        }

    }


}