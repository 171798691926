@media (max-width: 725px) {
    .deck-page {

        .deck-menu {
            flex-wrap: wrap;
            row-gap: 1rem;
            column-gap: 1rem;

            .menu-btn {
                width: 45%;
            }
        }
    }

    .navigate-btns {
        display: none !important;
    }

    .site-btn {
        width: 50% !important;
    }

    .flip-card {
        height: 80% !important;
    }

    .learn-container {

        .button-group {
            justify-content: center !important;
        }

        .answer {
            flex-basis: 100% !important;

            width: 100% !important;
        }
    }

    .create-deck {
        padding: 0rem 1rem;

        .term-card {
            .term-content {
                flex-wrap: wrap;
                height: 400px !important;

                .term {
                    width: 100% !important;
                    padding: .25rem !important;
                }

                .desc {
                    width: 100% !important;
                    padding: .25rem !important;
                }

                .img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    max-height: 140px;



                    .upload-btn {
                        border: 0.25rem dashed #d9dde8;
                        height: 8rem;
                        width: 10rem;

                    }

                }
            }

            .term-image {
                flex-wrap: wrap;

                .google-photos {
                    width: 100% !important;

                    .google-image {
                        width: 40%;
                    }
                }

                .local-upload {
                    width: 100% !important;
                }
            }
        }
    }
}


@media (max-width: 850px) {

    .home-page {
        section {
            .section-cards {
                .set-card {
                    width: 49%;
                }
            }
        }
    }



}

// Login and sign up -- below 330px
@media (max-width: 330px) {}