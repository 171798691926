.deck-page {
    max-width: 60rem;
    padding: 2.5rem;
    margin: auto;
    animation: fade-in 0.5s;

    .deck-header {
        h2 {
            color: $main-text-color;
            font-size: 2.5rem;
        }
    }

    .deck-menu {
        margin-top: 1rem;
        display: flex;
        column-gap: 2rem;
        justify-content: space-between;

        .menu-link {
            text-decoration: none;
            display: flex;
            justify-content: left;
            align-items: center;
            padding: .6rem .8rem;
            height: 100%;
            width: 100%;

            span {
                color: $main-text-color;
                font-weight: 600;
                font-size: 1rem;
                margin-left: 0.5rem;
            }
        }

        .lock>.menu-link {
            pointer-events: none;
        }

        .menu-btn {
            @include app_radius;
            @include border_bottom_purple;
            cursor: pointer;
            display: flex;
            justify-content: left;
            align-items: center;
            text-decoration: none;
            background-color: white;
            width: 24%;

        }


        .lock {
            cursor: not-allowed;
            border-bottom: 0.3125rem solid #fff;

            &:hover {
                border-bottom: 0.3125rem solid #fff;
            }
        }
    }

    .deck-progress {
        margin-top: 2rem;

        .quote-card {
            display: flex;
            margin-top: 2rem;
            justify-content: space-between;

            .quote {
                font-size: 2rem;
                font-weight: bold;
            }
        }

        .progress-card {

            h4 {
                font-size: 1.5rem;
                color: $main-text-color;
                margin-bottom: 2rem;
            }

            .result-card {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                row-gap: 2rem;

                .progress-detail {
                    display: flex;
                    flex-direction: column;
                    margin-left: 4rem;
                    width: 12rem;
                    justify-content: space-around;

                    .detail-row {
                        display: flex;
                        margin-bottom: .5rem;
                        font-size: 1.2rem;
                        font-weight: 500;
                        justify-content: space-between;

                        .progress-title {
                            margin-right: 2rem;
                            align-self: center;
                        }

                        .c-b {
                            color: $blue;
                        }

                        .c-p {
                            color: $main-purple;
                        }

                        .c-y {
                            color: $yellow;
                        }

                        .detail-number {
                            padding: .2rem .6rem;
                            border-radius: 1rem;
                        }

                        .detail-number.c-b {
                            border: 1px solid $blue;
                        }

                        .detail-number.c-p {
                            border: 1px solid $main-purple;
                        }

                        .detail-number.c-y {
                            border: 1px solid $yellow;
                        }

                    }

                }
            }

        }
    }

    .footer-group-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        padding-top: 1rem;
        column-gap: 1rem;
        border-top: 3px solid $main-text-color;

        .join-clone-group {
            display: flex;
            column-gap: 2rem;
        }

        .clone-btn {
            @include purple_btn;
        }

        .join-btn {
            @include blue_btn;
        }
    }
}