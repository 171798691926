.signup-login-page {

    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70vh;
        margin-top: 5%;

        form {
            width: 320px;

            h1 {
                font-family: (san-fran-bold, $back-up-fonts);
                font-size: 3rem;
                text-align: center;
                margin-bottom: 25px;
            }


            label {
                font-size: 0.75rem;
                color: rgba($dark-gray, 0.6);
            }

            input:not([type=submit]) {
                @include notion_input;
                padding: 8px;
                width: 100%;
                border-radius: 3px;
                font-size: 0.95rem;
                margin-bottom: 8px;
                margin-top: 3px;

                &::placeholder {
                    color: $medium-gray;
                }
            }

            input[type=submit] {
                width: 100%;
                padding: 8px;
                border-radius: 3px;
                font-size: 0.95rem;
                border: none;
                color: rgb(235, 87, 87);
                background: rgb(251, 235, 232);
                box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px, rgba(235, 87, 87, 0.3) 0px 0px 0px 1px inset;
                cursor: pointer;
                margin-top: 5px;
            }

            select {
                @include notion_input;
                padding: 8px;
                width: 100%;
                border-radius: 3px;
                font-size: 0.95rem;
                margin-bottom: 8px;
                margin-top: 3px;

            }

            $trimColor: rgb(235, 87, 87);

            .filupp>input[type="file"] {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
            }

            .filupp {
                position: relative;
                display: block;
                cursor: pointer;

                @include notion_input;
                width: 100%;
                padding: 8px;
                border-radius: 3px;
                font-size: 0.95rem;
                margin-bottom: 8px;
                margin-top: 3px;
            }

            .filupp:before {
                content: "";
                position: absolute;
                top: 0.7em;
                right: 0.58em;
                width: 1.2em;
                height: 1em;
                border: 2.6px solid $trimColor;
                border-top: 0;
                text-align: center;
            }


            .filupp:after {
                content: "\279c";
                position: absolute;
                transform: rotate(-90deg);
                top: .6em;
                right: .55em;
                font-size: 1.35em;
                color: $trimColor;
                line-height: 0;
            }

            .filupp-file-name {
                color: $medium-gray;
                width: 75%;
                text-overflow: ellipsis;
                line-height: 100%;
            }


            .bottom-text {
                text-align: center;
                margin-top: 30px;

                p,
                a {
                    display: inline;
                    margin-right: 5px;
                    color: rgba($dark-gray, 0.6);
                    font-size: 0.875rem;
                }

                a {
                    text-decoration: underline;
                }
            }


            .loginBT {
                border-radius: 3px;
                margin-bottom: 8px;
                margin-top: 15px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 7px;
                cursor: pointer;
                border: 1px solid rgba(15, 15, 15, 0.15);
                background-color: white;


                &:hover {
                    background-color: rgba(242, 241, 238, 0.6);
                }

                .buttonText {
                    color: rgb(17, 17, 17);
                    margin-left: 5px;
                    font-weight: 700;
                }

                .icon {
                    height: 20px;
                }
            }
        }

        .devicer {
            margin: 20px 0 20px;
            background-color: rgba(55, 53, 47, 0.16);
            height: 1px;
            width: 100%;
        }
    }
}